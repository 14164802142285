'use client'

import { Link } from '@pienso/components'
import { useEffect, useState } from 'react'
import * as s from './PreviewTab.css'

function PreviewTabClient() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (window.parent === window.self) {
      setIsVisible(true)
    }

    if (window.location.hostname === 'localhost') {
      setIsVisible(false)
    }
  }, [])

  return isVisible ? (
    <div className={s.previewTab}>
      <div className={s.previewTabContent}>
        <span>⚠️ You are in preview mode</span>
        <Link
          appearance={'primaryBlue'}
          prefetch={false}
          href="/api/exit-preview"
        >
          Exit Preview
        </Link>
      </div>
    </div>
  ) : null
}

export { PreviewTabClient }
